'use client';

import { useEffect, useMemo } from 'react';

import { usePathname } from 'next/navigation';
import posthog from 'posthog-js';
import { PostHogProvider as Provider } from 'posthog-js/react';

import { posthogDisabledPages } from '@/config/path';
import { useGetIdentifyDataForPosthog } from '@/hooks/useGetIdentifyDataForPosthog';

import isAppsumoUser from './api/appsumo/isAppsumoUser';

export function PostHogProvider({ children }) {
  const pathname = usePathname();

  const isDisabledPage = useMemo(() => {
    return posthogDisabledPages.some((page) => pathname.includes(page));
  }, [pathname]);

  const { data: identifyData, isLoading } = useGetIdentifyDataForPosthog({
    enabled: !isDisabledPage,
  });

  const isTestUser = useMemo(() => {
    return identifyData?.email.includes('popupsmart');
  }, [identifyData]);

  useEffect(() => {
    if (isDisabledPage) return;
    if (isLoading) return;

    const hasExistingSession =
      typeof window !== 'undefined' &&
      window.localStorage.getItem('ph_loaded') === 'true';

    if (typeof window !== 'undefined') {
      if (!hasExistingSession) {
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
          api_host: `${window.location.origin}/ingest`,
          disable_session_recording: isTestUser,
          session_recording: {
            recordCrossOriginIframes: true,
          },
          persistence: 'localStorage+cookie',
          cross_subdomain_cookie: true,
          loaded: (posthog) => {
            if (process.env.NODE_ENV === 'development') posthog.debug();
            window.localStorage.setItem('ph_loaded', 'true');
          },
        });
      }

      if (identifyData?.email) {
        posthog.identify(identifyData.userId, {
          email: identifyData.email,
          name: identifyData.name,
          plan_id: identifyData.planId,
          workspace_id: identifyData.workspaceId,
          is_appsumo: isAppsumoUser(identifyData.planId),
          chatbot_count: identifyData.chatbotCount,
          message_monthly: identifyData.messageMonthly,
          onboarding_cms: identifyData.cms,
          survey_industry: identifyData.surveyIndustry,
          demo_status: identifyData.demo_status,
          stage: process.env.NEXT_PUBLIC_ENVIRONMENT,
        });

        posthog.group('workspace', identifyData.workspaceId, {
          id: identifyData.workspaceId,
          name: identifyData.workspaceName,
        });
      } else {
        posthog.reset();
      }
    }
  }, [isDisabledPage, isTestUser, identifyData, isLoading]);

  if (isDisabledPage) return <>{children}</>;

  return <Provider client={posthog}>{children}</Provider>;
}
